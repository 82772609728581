import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";
// import {hasActivePlan} from "./auth.services";

axios.defaults.withCredentials = true;

export const createProject = (model) => {
    return axios.post(`${getPrivateURL()}projects` , model, {withCredentials:true})
};

export const updateProject = (model, id) => {
    return axios.put(`${getPrivateURL()}projects/${id}` , model, {withCredentials:true})
};

export const uploadData = (form_data, project_id) => {
    return axios.patch(`${getPublicURL()}projects/${project_id}`, form_data, {
        withCredentials: true,
        headers: {"Content-Type":"multipart/form-data"}}
    );
};
export const updateVideos = (data, project_id) => {
    return axios.post(`${getPublicURL()}projects_videos/${project_id}`, data, {withCredentials:true});
};
export const getProjects = () => {
    return axios.get(`${getPrivateURL()}projects` , null, {withCredentials:true})
};

export const getProject = (project_id) => {
    return axios.get(`${getPrivateURL()}projects/${project_id}` , null, {withCredentials:true})
};

export const getVideos = (project_id) => {
    return axios.get(`${getPrivateURL()}projects_videos/${project_id}` , null, {withCredentials:true})
};


export const disableProject = (project_id) => {
    return axios.patch(`${getPrivateURL()}projects/${project_id}` , null, {withCredentials:true})
};


export const removeProject = (project_id) => {
    return axios.delete(`${getPrivateURL()}projects/${project_id}` , null, {withCredentials:true})
};


const ProjectService = {
  createProject,
  getProjects,
  getVideos,
  getProject,
  disableProject,
  removeProject,
  uploadData,
  updateVideos,
  updateProject,
};
export default ProjectService;

