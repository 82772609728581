
import React, {useEffect, useState} from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
} from "reactstrap";
import DataTable from 'react-data-table-component';
import InfoIcon from "react-bootstrap-sweetalert/dist/components/InfoIcon";

import "./style.css"
import userService, {createUser, getUsers} from "../../../services/user.service";
import {array} from "prop-types";


function Registers() {
    const [isShowForm, setIsShowForm] = useState(false)
    const [data, setData] = useState([])
    const [inputVideos, setInputVideos] = useState()

    const columns = [
                    {
                        name: 'Project',
                        selector: row => row.project_name,
                        sortable: false,
                        width: "10%"
                    },
                    {
                        name: 'Ip',
                        selector: row => row.ip,
                        sortable: false,
                        width: "10%"
                    },
                    {
                        name: 'Created At',
                        selector: row => row.created_at,
                        sortable: true,
                        width: "10%"
                    },
                    {
                        name: 'User-Agent',
                        selector: row => row.user_agent,
                        sortable: false,
                        width: "50%"
                    },
                    {
                        name: 'Action',
                        selector: row => row.action,
                        sortable: false,
                        button:true,
                        width: "20%"
                    },
                ]

    useEffect(()=>{
        getUsers().then((response)=>{
            if (response.data !== undefined && response.data !== null){
                let dlist = []
                response.data.forEach(item=>{
                    dlist.push({
                      id: item.id,
                      project_name: item.project_name,
                      ip: item.ip,
                      user_agent: item.user_agent,
                      created_at: item.created_at,
                      action:(
                          <>
                            <Button onClick={(e)=>showForm(item.form)} className={"btn btn-sm btn-outline-warning"} >Show Form</Button>
                          </>
                      )
                  })
                })
                setData(dlist)
            }
        }, (error)=>{
            console.log(error)
        })
    },[setData])
    const showForm = (form) =>{
        let jsonForm = JSON.parse(form)

        setInputVideos(jsonForm)
        // setInputVideos()
        handleToggleModal()
    }
    function ShowForm(){
        return  Object.entries(inputVideos).map(item=>{
            return (<div className={"col-12 border my-1"}>
                <label className={"float-left"}>{item[0]}</label>
                <label>:</label>
                <label className={"float-right"}>{item[1]}</label>
            </div>)
        });
    }

    const handleToggleModal = (e) => setIsShowForm(!isShowForm)
    return (
        <>
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                      <Modal size={"md"} isOpen={isShowForm}  toggle={handleToggleModal} >
                          <ModalHeader toggle={handleToggleModal}>Filled Form</ModalHeader>
                          <ModalBody color={"light"}>
                              <ShowForm  />
                          </ModalBody>
                      </Modal>
                    <DataTable
                          columns={columns}
                          data={data}
                          pagination={true}
                          expandableRows={false}
                          title={"Registers List"}
                          responsive={true}
                          defaultSortAsc={true}
                          expandableInheritConditionalStyles={true}
                          noTableHead={false}
                          defaultSortFieldId={"phone_number"}
                          // pointerOnHover={true}
                          highlightOnHover={true}

                    />

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
    );
}

export default Registers;
