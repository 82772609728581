
import React, {useEffect, useRef, useState} from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label, FormText, DropdownItem, DropdownMenu, Dropdown, DropdownToggle,
} from "reactstrap";
import DataTable from 'react-data-table-component';

import "./style.css"
import {
    getVideos,
    createProject,
    getProjects,
    getProject,
    disableProject,
    uploadData,
    updateVideos,
    updateProject
} from "../../../services/project.service";
import ProjectService from "../../../services/project.service";
import {getOutputURL} from "../../../services/core.service"
import Typography from "../../components/Typography";
import imageSrc  from "assets/img/bg/damir-bosnjak.jpg"

function Projects() {

    const columns = [
                {
                    name: 'Project',
                    selector: row => row.name,
                    sortable: false,
                },
                {
                    name: 'Receiver',
                    selector: row => row.receiver_email,
                    sortable: false,
                },
                {
                    name: 'Created At',
                    selector: row => row.created_at,
                    sortable: true,
                },
                {
                    name: 'Button',
                    selector: row => row.submit_button,
                    sortable: false,
                },
                {
                    name: 'Font',
                    selector: row => row.font,
                    sortable: false,
                },
                {
                    name: 'Status',
                    selector: row => row.status,
                    sortable: false,
                },
                {
                    name: 'Action',
                    selector: row => row.action,
                    sortable: false,
                    button:true,
                    width:'20rem',
                },
            ]

    const [selectedId, setSelectedId] = useState(null)
    const [show, setShow] = useState(false)
    const [showVideos, setShowVideos] = useState(false)
    const [showUrl, setShowUrl] = useState(false)
    const [inputFields, setInputFields] = useState([{fieldName:'', fieldType:''} ]);
    const [inputVideos, setInputVideos] = useState([{
        videoUrl:'',
        answers1: {text:"", call_back:""},
        answers2: {text:"", call_back:""},
        answers3: {text:"", call_back:""},
        answers4: {text:"", call_back:""},
        answers5: {text:"", call_back:""},
        answers6: {text:"", call_back:""},
    } ]);
    const defaultValues = {name:"Project 1", submitButton:"Submit", font:"tahoma"}
    const [data,setData] = useState([])
    const [clipTextStatus,setClipTextStatus] = useState("")
    const [status,setStatus] = useState("")
    const [editMode,setEditMode] = useState(false)
    const [editedId,setEditedId] = useState("")
    const defaultVideoValues =  {
        videoUrl:'',
        answers1: {text:"", call_back:""},
        answers2: {text:"", call_back:""},
        answers3: {text:"", call_back:""},
        answers4: {text:"", call_back:""},
        answers5: {text:"", call_back:""},
        answers6: {text:"", call_back:""},
    }
    useEffect(()=>{
        getProjects().then((response)=>{
            if (response.data !== undefined && response.data !== null){
                let dlist = []
                response.data.forEach(item=>{
                    dlist.push({
                      id: item.id,
                      submit_button: item.submit_button,
                      receiver_email: item.receiver_email,
                      created_at: item.created_at,
                      name:  item.name,
                      font: item.font,
                      status:(item.is_active ? <Badge color="success" pill>active</Badge> : <Badge color="danger" pill>Not Active</Badge>),
                      action:(
                          <>
                          <Button onClick={(e)=> {
                              setEditedId(item.id);
                              setEditMode(true);
                              handleToggleModal(e, true, item.id);
                          }} className={"btn btn-sm btn-outline-warning"} >Edit</Button>
                          {/*<Button onClick={(e)=> {*/}
                          {/*    setEditedId(item.id);*/}
                          {/*    setEditMode(true);*/}
                          {/*    handleToggleModal(e, true, item.id);*/}
                          {/*}} className={"btn btn-sm btn-outline-primary"} >Form</Button>*/}
                          <Button onClick={(e)=> {
                              setEditedId(item.id);
                              setEditMode(true);
                              handleToggleShowVideosModal(e, true, item.id);
                          }} className={"btn btn-sm btn-outline-default"} >Videos</Button>
                          <Button onClick={(e)=>  {
                              handleToggleModalUrl(e, btoa(item.id))
                          }} className={"btn btn-sm btn-outline-info"} >URL</Button>
                          </>
                      )
                  })
                })
                setData(dlist)
            }
            // console.log(response)
        }, (error)=>{
            console.log(error)
        })
    },[setData])
    const addInputField = ()=> {
        try{
            // console.log(inputFields)
            setInputFields([...inputFields, {fieldName:'',fieldType:''} ])
        }catch (e){
            console.error(e)
        }
    }
    const addInputVideoCard = ()=> {
        setInputVideos([...inputVideos, defaultVideoValues])
    }
    const showLink = (id) =>{

    }
    const [values, setValues] = useState(defaultValues)
    const handleChange = (data) => setValues(data)
    const handleActionClicked = (e) =>{
        if(values.name.length < 4){
            alert("project Name is not valid")
            return
        }
        values["fields"] = inputFields
        // alert(editedId)
        if(editMode){
            updateProject(values, editedId)
                   .then((value)=>{
                      console.log(value)
                       window.location.reload()
                    }, (error) => {

                   })
        }else{
            createProject(values)
               .then((value)=>{
                  console.log(value)
                   window.location.reload()
                }, (error) => {

               })
        }
    }
    const handleToggleModal = (e, editMode, editedId) => {
        setInputFields([{fieldName:'',fieldType:''} ]);
        setValues(defaultValues);
        if(show){
            setShow(!show);
            return
        }
        // alert( editedId)
        if(editMode){
            getProject(editedId).then(
                (data)=>{
                    setValues(data.data.model);
                    if (data.data.fields != null){
                        setInputFields(data.data.fields)
                    }
                }).catch((e)=>{
                    alert("error in fetch data");
                    return
                });
        }else{
            setInputFields([{fieldName:'',fieldType:''} ]);
            setValues(defaultValues);
        }
        setShow(!show);
    }
    const handleToggleModalUrl = (e, id) => {
        setShowUrl(!showUrl)
        setClipTextStatus("")
        setSelectedId(id)
        handleChangeCode(id)
    }
    const handleToggleShowVideosModal = (e, editMode, editedId) => {
        setInputVideos([defaultVideoValues ]);
        // alert(editMode)
        setEditedId(editedId)
        if(showVideos){
            setShowVideos(!showVideos);
            return
        }
        if(editMode){
            getVideos(editedId).then( (data)=>{
                    console.log(data.data)
                    if(data.data !== null && data.data !== undefined){
                        setInputVideos(data.data)
                    }
                }).catch((e)=>{
                    alert("error in fetch data");
                    return
                });
        }else{
            setInputVideos([defaultVideoValues]);
            // setValues(defaultValues);
        }
        setShowVideos(!show);
    }

    const removeInputFields = (index)=>{
        if(confirm("are you sure to delete this item ?")){
            const rows = [...inputFields];
            rows.splice(index, 1);
            setInputFields(rows);
        }
   }
    const handleChangeText = (index, event)=>{
        const { name, value } = event.target;
        // alert(index + name + value)
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
    }
    const removeInputCard = (index)=>{

        if(confirm("are you sure to delete this item " +index+" ?")){
            const rows = [...inputVideos];
            rows.splice(index, 1);
            setInputVideos(rows);
        }
   }
    const handleChangeAnswer = (index, event, action)=>{

        const { name, value} = event.target;
        const list = [...inputVideos];
        if(action === "text" || action === "call_back"){
            list[index][name][action] = value // dict value

        } else {
            list[index]["videoUrl"] = action // video url
        }
        console.log(list)
        setInputVideos(list);
    }


    const handleCopyLink = (e) =>{
        navigator.clipboard.writeText(renderedCode);
        setClipTextStatus("link Copied to clipboard")
    }

const [videoSelectedIndex, setVideoSelectedIndex] = useState(0)
    const inputRef = useRef(null);
    const openFileDialog = (e, index) => {
        setVideoSelectedIndex(index)
        inputRef.current.click();
    }
    const [uploadingFile,setUploadingFile] = useState(false);
    const handleFileChange = (event) => {
        // alert(videoSelectedIndex)
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
          return;
        }
        const data = new FormData();
        data.append('data_file', fileObj, fileObj.name);
        setUploadingFile(true)
        ProjectService.uploadData(data, editedId).then(data => {
            setUploadingFile(false)
            handleChangeAnswer(videoSelectedIndex, event, data.data["data_path"])
        }).catch((e)=>{
            setUploadingFile(false)
            alert("error in upload video" + e)
        })

    };


    const handleSaveVideos = e =>{
        ProjectService.updateVideos(inputVideos, editedId)
            .then((data)=>{
                setStatus("Done.")
                setTimeout(()=>{
                    window.location.reload();
                }, 1400)
            }).catch((e)=>{
                setStatus("Error -> " + e)
            });

    }
    const [typeOfFrame, setTypeOfFrame] = useState("widget")
    const [activeMode, setActiveMode] = useState("squire")
    const [iframeHeight, setIframeHeight] = useState(0)
    const [renderedCode, setRenderedCode] = useState("")
    const [buttonColor, setButtonColor] = useState("#4f4231")
    const [buttonText, setButtonText] = useState("Show")
    const handleChangeCode = (id) =>{
        setRenderedCode(`
<!-- Add the code below inside the <body> tags of your HTML page -->
<script>
    window.EMBED_CONFIG = {
        "kind": "${typeOfFrame}",
        "url": "https://player.dcmarketing.ca/video-ask/${id}?thumb=true&color=${buttonColor}",
        "thumbnail": "https://player.dcmarketing.ca/video-ask/${id}",
        "options": { 
            "btnName" : "${buttonText}", 
            "btnFrame": "${activeMode}", 
            "btnBgColor": "${buttonColor}"
        }
    }
</script>
<script src="https://player.dcmarketing.ca/embed.js"></script>`)
    }
    return (
        <>
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                    <Modal size={"xl"} maxWidth={"xl"} isOpen={showVideos}  toggle={(e)=>handleToggleShowVideosModal(e,false)} >
                        <ModalHeader toggle={(e)=>handleToggleShowVideosModal(e,false)}>
                            Videos
                        </ModalHeader>
                        <ModalBody color={"light"}>
                            <div className={"col-12 "}>
                                <button className={"btn btn-large btn-outline-primary"} onClick={addInputVideoCard}>+ Insert New Video</button>
                            </div>
                            {
                                 inputVideos && inputVideos.map((data, index)=>{
                                          const {videoUrl,  answers1 , answers2, answers3, answers4, answers5, answers6} = data;

                                          return(
                                                <div className="row my-3 border" key={index}>
                                                    <div className={"col-12"}>
                                                        <h5 className={"text-body text-center bold"} style={{"marginTop":"20px"}} >Video {index + 1}</h5>
                                                    </div>
                                                    <div className="col-1"  >
                                                        {(inputVideos.length!==1)?
                                                            <button className="btn btn-outline-danger"
                                                                    onClick={(e)=>removeInputCard(index)}>x</button>:''}
                                                    </div>
                                                    <div className="col-4" style={{ margin:"1rem"}} >
                                                        <video src={videoUrl} controls={true} style={{"width":"100%", "height":"80%"}} />
                                                         <div className="form-group">

                                                             <input style={{display: 'none'}} ref={inputRef} type="file"
                                                                   onChange={handleFileChange} accept={"video/mp4"}/>

                                                             <Button disabled={uploadingFile} className={"btn btn-fill btn-behance"}
                                                                    style={{"width":"100%"}} onClick={(e) => openFileDialog(e, index) }> + Upload Video</Button>

                                                            {/*<input name="changeVideo" className="form-control" type={"file"}*/}
                                                            {/*        onChange={(event)=>handleChangeAnswer(index, event)} />*/}
                                                         </div>
                                                    </div>
                                                    <div className="col-6" style={{marginTop:"2rem"}}>
                                                        <div className={"row"}>
                                                            <div className={"col-6"}>
                                                                <div className="form-group">
                                                                    <input value={answers1 && answers1.text} name="answers1" className="form-control"
                                                                            onChange={(event)=>handleChangeAnswer(index, event, "text")} placeholder="Answer 1" />
                                                                </div>
                                                                <div className="form-group">
                                                                    <input value={answers2 && answers2.text} name="answers2" className="form-control"
                                                                            onChange={(event)=>handleChangeAnswer(index, event, "text")} placeholder="Answer 2" />
                                                                </div>
                                                                <div className="form-group">
                                                                    <input value={answers3 && answers3.text} name="answers3" className="form-control"
                                                                            onChange={(event)=>handleChangeAnswer(index, event, "text")} placeholder="Answer 3" />
                                                                </div>
                                                                <div className="form-group">
                                                                    <input value={answers4 && answers4.text} name="answers4" className="form-control"
                                                                            onChange={(event)=>handleChangeAnswer(index, event, "text")} placeholder="Answer 4" />
                                                                </div>
                                                                <div className="form-group">
                                                                    <input value={answers5 && answers5.text} name="answers5" className="form-control"
                                                                            onChange={(event)=>handleChangeAnswer(index, event, "text")} placeholder="Answer 5" />
                                                                </div>
                                                                <div className="form-group">
                                                                    <input value={answers6 && answers6.text} name="answers6" className="form-control"
                                                                            onChange={(event)=>handleChangeAnswer(index, event, "text")} placeholder="Answer 6" />
                                                                </div>
                                                            </div>
                                                            <div className={"col-6"}>
                                                                <div className="form-group">
                                                                    <select value={answers1 && answers1.call_back} name="answers1" className="form-control"
                                                                            onChange={(event)=>handleChangeAnswer(index, event, "call_back")} >
                                                                         <option value={0}>Show Form</option>
                                                                        {
                                                                            inputVideos.map((insideData, insideIndex)=>{
                                                                                const {fieldName, fieldType} = insideData;
                                                                                if(index !== insideIndex){
                                                                                    return <option value={insideIndex+1}>Video {insideIndex + 1}</option>
                                                                                }
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="form-group">
                                                                    <select value={answers2 && answers2.call_back} name="answers2" className="form-control"
                                                                            onChange={(event)=>handleChangeAnswer(index, event, "call_back")} >
                                                                         <option value={0}>Show Form</option>
                                                                        {
                                                                            inputVideos.map((insideData, insideIndex)=>{
                                                                                // const {fieldName, fieldType} = insideData;
                                                                                if(index !== insideIndex){
                                                                                    return <option  value={insideIndex+1}>Video {insideIndex+1 }</option>
                                                                                }
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="form-group">
                                                                    <select value={answers3 && answers3.call_back} name="answers3" className="form-control"
                                                                            onChange={(event)=>handleChangeAnswer(index, event, "call_back")} >
                                                                         <option value={0}>Show Form</option>
                                                                        {
                                                                            inputVideos.map((insideData, insideIndex)=>{
                                                                                const {fieldName, fieldType} = insideData;
                                                                                if(index !== insideIndex){
                                                                                    return <option value={insideIndex+1}>Video {insideIndex + 1}</option>
                                                                                }
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="form-group">
                                                                    <select value={answers4 && answers4.call_back} name="answers4" className="form-control"
                                                                            onChange={(event)=>handleChangeAnswer(index, event, "call_back")} >

                                                                         <option value={0}>Show Form</option>
                                                                        {

                                                                            inputVideos.map((insideData, insideIndex)=>{
                                                                                const {fieldName, fieldType} = insideData;
                                                                                if(index !== insideIndex){
                                                                                    return <option value={insideIndex+1}>Video {insideIndex + 1}</option>
                                                                                }
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="form-group">
                                                                    <select value={answers5 && answers5.call_back} name="answers5" className="form-control"
                                                                            onChange={(event)=>handleChangeAnswer(index, event, "call_back")} >

                                                                         <option value={0}>Show Form</option>
                                                                        {

                                                                            inputVideos.map((insideData, insideIndex)=>{
                                                                                const {fieldName, fieldType} = insideData;
                                                                                if(index !== insideIndex){
                                                                                    return <option value={insideIndex+1}>Video {insideIndex + 1}</option>
                                                                                }
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="form-group">
                                                                    <select value={answers6 && answers6.call_back} name="answers6" className="form-control"
                                                                            onChange={(event)=>handleChangeAnswer(index, event, "call_back")} >

                                                                         <option value={0}>Show Form</option>
                                                                        {

                                                                            inputVideos.map((insideData, insideIndex)=>{
                                                                                const {fieldName, fieldType} = insideData;
                                                                                if(index !== insideIndex){
                                                                                    return <option value={insideIndex+1}>Video {insideIndex + 1}</option>
                                                                                }
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                          )
                                    })
                            }
                        </ModalBody>
                        <ModalFooter style={{margin:"20px"}}>
                            <p className={"text text-dark"}>{status}</p>
                            <Button className={"btn btn-lg btn-outline-success"} onClick={handleSaveVideos}>Save Changes</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal size={"md"} isOpen={showUrl}   toggle={e=>handleToggleModalUrl(e)} >
                        <ModalHeader toggle={e=>handleToggleModalUrl(e)}>
                            Show Url
                        </ModalHeader>
                        <ModalBody color={"light"}>
                            <div className={"row"}>
                                <div className={"col-2 my-2 "}  >
                                    <Label className={"control-label"}>Type:</Label>
                                </div>
                                <div className={"col-10  "}  >
                                    <select className={"form-control"} onChange={e=> setTypeOfFrame(e.target.value)}>
                                        {/*<option value={"iframe"}>Iframe</option>*/}
                                        <option value={"widget"}>Widget</option>
                                    </select>
                                </div>
                                <div className={"col-12 row my-2 text-center"} style={{"display": typeOfFrame === "iframe" ? "none" : "flex"}}>
                                    <div className={"col-4"} onClick={(e)=>{
                                        setActiveMode("squire");
                                        handleChangeCode(selectedId);
                                    }}  >
                                        <svg fill="none" height="40" width="40" xmlns="http://www.w3.org/2000/svg">
                                            <rect fill={activeMode === "squire" ? "green" : "#111"} fill-opacity="0.4" height="40" rx="8" width="40"></rect>
                                            <rect fill="#fff" height="16" rx="2" width="22" x="14" y="20"></rect>
                                        </svg>
                                    </div>
                                    <div className={"col-4  "} onClick={(e)=>{
                                        setActiveMode("circle");
                                        handleChangeCode(selectedId);
                                    }}  >
                                        <svg fill="none" height="40" width="40" xmlns="http://www.w3.org/2000/svg">
                                            <rect fill={activeMode === "circle" ? "green" : "#111"} fill-opacity="0.4" height="40" rx="8" width="40"></rect>
                                            <circle cx="28" cy="28" fill="#fff" r="8"></circle>
                                        </svg>
                                    </div>
                                    <div className={"col-4  "} >
                                        <Input type={"color"} className={"form-control"} value={buttonColor}
                                               style={{"height":"3rem", "width":"5rem"}}
                                               onChange={e=>{
                                                   setButtonColor(e.target.value);
                                                   handleChangeCode(selectedId);
                                               }} />
                                    </div>
                                    {/*<div className={"col-2 py-2 pl-1  "} >*/}
                                    {/*    <Label className={"text-dark"}>Direction:</Label>*/}
                                    {/*</div>*/}
                                    {/*<div className={"col-4  "} disabled >*/}
                                    {/*    <select className={"form-control"}>*/}
                                    {/*        <option value={"right-button"}>Right</option>*/}
                                    {/*        <option value={"left-button"}>Left</option>*/}
                                    {/*    </select>*/}
                                    {/*</div>*/}
                                </div>
                                <div className={"col-12 my-2"}  style={{"display": typeOfFrame === "widget" ? "none" : "flex"}}>
                                    <div className={"col-6"}  >
                                        <Label className={"control-label"}>Width (%):</Label>
                                        <Input type={"number"} style={{"width":"100%"}} className={"form-control"} readOnly={true} value={100}  />
                                    </div>
                                    <div className={"col-6"} >
                                        <Label className={"control-label"}>Height (PX):</Label>
                                        <Input type={"number"} style={{"width":"100%"}} className={"form-control"}
                                               onChange={e=>setIframeHeight(e.target.value)}
                                               readOnly={false} value={iframeHeight} min={10}  />
                                    </div>
                                </div>

                            </div>
                            <div className={"col-12"} style={{"marginTop":"1rem"}}>
                                <Input id={"ls"} className={"input-group"} tag={"textarea"} style={{"height":"15rem"}} type={"text"}
                                       value={renderedCode} readOnly={true} />
                            </div>
                            <div className={"col-12 text-center" }>
                                <Button className={"btn btn-sm btn-info"} onClick={handleCopyLink}>Copy</Button>
                            </div>
                            {clipTextStatus}
                        </ModalBody>
                    </Modal>
                    <Modal size={"lg"} isOpen={show}  toggle={(e)=>handleToggleModal(e, false)} >
                        <ModalHeader toggle={(e)=>handleToggleModal(e, false)}>
                            {editMode ? "Edit Project" : "Insert New Project"}
                        </ModalHeader>
                        <ModalBody color={"light"}>
                            <Label>Project Name (*):</Label>
                            <Input className={"input-group"} value={values.name}
                                   onChange={(e)=>handleChange({...values, name:e.target.value})} />
                            <Label>Email To Receive (*):</Label>
                            <Input className={"input-group"} value={values.receiver_email} type={"email"}
                                   onChange={(e)=>handleChange({...values, receiver_email:e.target.value})} />
                            <Label>Submit Button Text (*):</Label>
                            <Input className={"input-group"} value={values.submit_button} type={"text"}
                                   onChange={(e)=>handleChange({...values, submit_button:e.target.value})} />

                            <Label>Font Face:</Label>
                              <Input id="exampleSelect" value={values.font} name="select" type="select"
                                     onChange={(e)=>handleChange({...values, font:e.target.value})} >
                                <option value={"Tahoma"}>
                                  Tahoma
                                </option>
                                <option value={"Sans"}>
                                  Sans
                                </option>
                                <option value={"Lato"}>
                                  Lato
                                </option>
                                <option value={"Open Sans"}>
                                  Open Sans
                                </option>
                              </Input>
                            <hr />
                            <div>
                                Click To add custom field
                                {
                                    inputFields && inputFields.map((data, index)=>{
                                          const {fieldName, fieldType} = data;
                                          return(
                                                <div className="row my-3" key={index}>
                                                    <div className="col-9" style={{marginTop:"0.6rem"}}>
                                                        <div className={"row"}>
                                                            <div className={"col-6"}>
                                                                <div className="form-group">
                                                                    <input type="text" onChange={(event)=>handleChangeText(index, event)}
                                                                           value={fieldName} name="fieldName" className="form-control"
                                                                           placeholder="Field Name" />
                                                                </div>
                                                            </div>
                                                            <div className={"col-6"}>
                                                                <div className="form-group">
                                                                    <select value={fieldType} name="fieldType" className="form-control"
                                                                            onChange={(event)=>handleChangeText(index, event)} placeholder="Field Type">
                                                                        <option value={"text"}>Text</option>
                                                                        <option value={"number"}>Number</option>
                                                                        <option value={"email"}>Email</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-3" >
                                                        {(inputFields.length!==1)?
                                                            <button className="btn btn-outline-danger"
                                                                    onClick={(e)=>removeInputFields(index)}>x</button>:''}
                                                    </div>
                                                </div>
                                          )
                                    })
                                }
                                <div className="row">
                                    <div className="col-sm-12">
                                        <button className="btn btn-outline-success " onClick={addInputField}>+ Add New</button>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{marginRight:"20px"}}>
                            <Button className={"btn btn-lg btn-info"} onClick={handleActionClicked}>Save</Button>
                        </ModalFooter>
                    </Modal>
                    <CardHeader>
                    <CardTitle >
                        <Button className="btn-outline-success " style={{"float":"right"}}
                                color="success"
                                onClick={(e)=> {handleToggleModal(e, false);}}>Insert New Project</Button>
                    </CardTitle>
                  </CardHeader>
                    <CardBody>
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination={true}
                          expandableRows={false}
                          title={"Projects List"}
                          responsive={true}
                          defaultSortAsc={true}
                          expandableInheritConditionalStyles={true}
                          noTableHead={false}
                          defaultSortFieldId={"id"}
                          // pointerOnHover={true}
                          highlightOnHover={true}
                        />
                    </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
    );
}

export default Projects;
