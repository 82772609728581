
import React, {useEffect, useState} from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
} from "reactstrap";
import DataTable from 'react-data-table-component';
import InfoIcon from "react-bootstrap-sweetalert/dist/components/InfoIcon";

import "./style.css"
import userService, {createUser, getUsers} from "../../../services/user.service";
import {array} from "prop-types";


function Members() {
    const doDisable = (id) =>{
        if(confirm("Do you want to disable/enable this user ? ")){
            userService.disableUser(id).then((data)=>{
                console.log(data)
                window.location.reload()
            },(error)=>{
                alert(error)
               console.log(error)
            })
        }
    }
  const columns = [
                    {
                        name: 'Project',
                        selector: row => row.project_name,
                        sortable: false,
                    },
                    {
                        name: 'Ip',
                        selector: row => row.ip,
                        sortable: false,
                    },
                    {
                        name: 'Created At',
                        selector: row => row.created_at,
                        sortable: true,
                    },
                    {
                        name: 'User-Agent',
                        selector: row => row.user_agent,
                        sortable: false,
                    },
                    {
                        name: 'Action',
                        selector: row => row.action,
                        sortable: false,
                        button:true,
                    },
                ]
    const showForm = (form) =>{

    }
  const [data,setData] = useState([])
    useEffect(()=>{
        getUsers().then((response)=>{
            if (response.data !== undefined && response.data !== null){
                let dlist = []
                response.data.forEach(item=>{
                    dlist.push({
                      id: item.id,
                      project_name: item.project_name,
                      ip: item.ip,
                      user_agent: item.user_agent,
                      created_at: item.created_at,
                      action:(
                          <>
                            <Button onClick={(e)=>showForm(item.form)} className={"btn btn-sm btn-outline-warning"} >Show Form</Button>
                          </>
                      )
                  })
                })
                setData(dlist)
            }
            // console.log(response)
        }, (error)=>{
            console.log(error)
        })
    },[])
    const [values,setValues] = useState({role:"subscriber", phone:""})
    const handleChange = (data) => {
      setValues(data)
    }
    const handleActionClicked = (e) =>{
       if(values.phone.length === 4){
           createUser(values)
           .then((value)=>{
              console.log(value)
               window.location.reload()
            }, (error) => {

           })
       }else{
           alert("phone number is not valid")
       }
    }
    const [show, setShow] = useState(false)
    const handleToggleModal = (e) => setShow(!show)


  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
                <Modal isOpen={show}  toggle={handleToggleModal} >
                    <ModalHeader toggle={handleToggleModal}>
                        Insert New User
                    </ModalHeader>
                    <ModalBody color={"light"}>
                        <Label>Phone (4 Numbers, Eg: 1234):</Label>
                            <Input className={"input-group"} value={values.phone} type={"number"} max={9999}
                                   onChange={(e)=>handleChange({...values, phone:e.target.value})} />
                            <Label>Type:</Label>
                              <Input id="exampleSelect" name="select" type="select"
                                     onChange={(e)=>handleChange({...values, role:e.target.value})} >
                                <option value={"subscriber"}>
                                  Subscriber
                                </option>
                                <option value={"publisher"}>
                                  Publisher
                                </option>
                              </Input>
                    </ModalBody>
                    <ModalFooter style={{marginRight:"20px"}}>
                        <Button className={"btn btn-lg btn-info"} onClick={handleActionClicked}>Save Information</Button>
                    </ModalFooter>
                </Modal>
              <CardHeader>
                <CardTitle >
                    <Button className="btn-outline-success " style={{"float":"right"}}
                            color="success" href="#pablo" onClick={handleToggleModal}>Insert New Member</Button>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <DataTable
                      columns={columns}
                      data={data}
                      pagination={true}
                      expandableRows={false}
                      title={"Members List"}
                      responsive={true}
                      defaultSortAsc={true}
                      expandableInheritConditionalStyles={true}
                      noTableHead={false}
                      defaultSortFieldId={"phone_number"}
                      // pointerOnHover={true}
                      highlightOnHover={true}

                />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Members;
