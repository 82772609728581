
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col, Button, Badge,
} from "reactstrap";
import DataTable from 'react-data-table-component';
import InfoIcon from "react-bootstrap-sweetalert/dist/components/InfoIcon";


function RegularTables() {
  const columns = [
                    {
                        name: 'Username',
                        selector: row => row.username,
                        sortable: false,
                    },
                    {
                        name: 'Phone',
                        selector: row => row.phone_number,
                        sortable: false,
                    },
                    {
                        name: 'Password',
                        selector: row => row.password,
                        sortable: false,
                    },
                    {
                        name: 'Created At',
                        selector: row => row.created_at,
                        sortable: true,
                    },
                    {
                        name: 'Last Call',
                        selector: row => row.last_call,
                        sortable: false,
                    },
                    {
                        name: 'Status',
                        selector: row => row.status,
                        sortable: false,
                    },
                    {
                        name: 'Action',
                        selector: row => row.action,
                        sortable: false,
                        button:true,
                    },
                ]
  const data = [
                  {
                      id: 1,
                      username: 'Beetlejuice',
                      phone_number: '98516',
                      password: 'Beetlejuice',
                      created_at: '21 Aug 2023',
                      last_call: '20 minutes ago',
                      year: '1988',
                      status:(<Badge color="success" pill>active</Badge>),
                      action:(<Button className={"btn btn-sm btn-outline-warning"} >Disable</Button>)
                  },
                  {
                      id: 2,
                      username: 'Beetlejuice',
                      phone_number: '98517',
                      password: 'Beetlejuice',
                      created_at: '21 Aug 2023',
                      last_call: '17 minutes ago',
                      year: '1988',
                      status:(<Badge color="success" pill>active</Badge>),
                      action:(<Button className={"btn btn-sm btn-outline-warning"} >Disable</Button>)
                  },
                  {
                      id: 3,
                      username: 'Beetlejuice',
                      phone_number: '98518',
                      password: 'Beetlejuice',
                      created_at: '21 Aug 2023',
                      last_call: '14 minutes ago',
                      year: '1988',
                      status:(<Badge color="success" pill>active</Badge>),
                      action:(<Button className={"btn btn-sm btn-outline-warning"} >Disable</Button>)
                  },
                  {
                      id: 4,
                      username: 'Beetlejuice',
                      password: 'Beetlejuice',
                      phone_number: '98519',
                      created_at: '21 Aug 2023',
                      last_call: '12 minutes ago',
                      year: '1988',
                      status:(<Badge color="success" pill>active</Badge>),
                      action:(<Button className={"btn btn-sm btn-outline-warning"} >Disable</Button>)
                  },
               ]
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle ><Button
                  className="btn-outline-success "
                  style={{"float":"right"}}
                  color="success"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Insert New Member
                </Button></CardTitle>
              </CardHeader>
              <CardBody>
                <DataTable
                      columns={columns}
                      data={data}
                      pagination={true}
                      expandableRows={false}
                      title={"Members List"}
                      responsive={true}
                      defaultSortAsc={true}
                      expandableInheritConditionalStyles={true}
                      noTableHead={false}
                      pointerOnHover={true}
                      highlightOnHover={true}

                />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default RegularTables;
