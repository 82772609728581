// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-info{
        background-color: #51bcda !important;
}

.list-container {
  display: flex;
  font-size: 18px;
  background-color: #eee;
  flex-direction: column;
  margin-bottom: 1rem;
}
.item-container {
  background-color: #fff;
  border: 1px solid black;
  padding: 25px 70px;
  margin: 15px 50px;
}
`, "",{"version":3,"sources":["webpack://./src/views/tables/projects/style.css"],"names":[],"mappings":"AAAA;QACQ,oCAAoC;AAC5C;;AAEA;EACE,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".btn-info{\n        background-color: #51bcda !important;\n}\n\n.list-container {\n  display: flex;\n  font-size: 18px;\n  background-color: #eee;\n  flex-direction: column;\n  margin-bottom: 1rem;\n}\n.item-container {\n  background-color: #fff;\n  border: 1px solid black;\n  padding: 25px 70px;\n  margin: 15px 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
