import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";
// import {hasActivePlan} from "./auth.services";

axios.defaults.withCredentials = true;

const editProfile = (country_id) => {
    return axios.get(`${getPrivateURL()}provinces/${country_id.toString()}`)
};

const getProfile = () =>{
    return axios.post(`${getPrivateURL()}users/me/`, {} ,{withCredentials:true})
}

const changePassword = (values) =>{
    return axios.put(`${getPrivateURL()}users/change_password` , values, {withCredentials:true})
}
const changePaymentMethod = (values) =>{
    return axios.put(`${getPrivateURL()}users/change_payment_method` , values, {withCredentials:true})
}
const updateUser = (user_id, values) =>{
    return axios.put(`${getPrivateURL()}users/` + user_id , values, {withCredentials:true})
}

const changeAvatar = (avatarFormData) =>{
    return  axios.post(
        `${getPrivateURL()}users/change_avatar/` ,
        avatarFormData,
        {
            withCredentials:true, 
            headers: {'Content-Type': 'multipart/form-data'}
        })
}

export const getUsers = () => {
    return axios.get(`${getPrivateURL()}registers` ,{withCredentials:true})
}

export const getCalls = () => {
    return axios.get(`${getPrivateURL()}calls` ,{withCredentials:true})
}

export const getUser = (user_id) => axios.get(`${getPrivateURL()}users/` + user_id ,{withCredentials:true})
export const createUser = (data) => {
    return axios.post(`${getPrivateURL()}users` , data,{withCredentials:true})
};
export const addCard = (data) => {
    return axios.post(`${getPrivateURL()}add_card` , data,{withCredentials:true})
};
export const resendConfirmEmail = (user_id) => {
    return axios.post(`${getPrivateURL()}users/${user_id}/resend_confirm` , null, {withCredentials:true})
};
export const disableUser = (user_id) => {
    return axios.delete(`${getPrivateURL()}users/${user_id}` , null, {withCredentials:true})
};

// export const hasActivePlan = () => {
//     return localStorage.getItem("") === false
// };

export const userRole = () => {
    return localStorage.getItem("permission")
};

export const isAdminLogin = () => {
    return localStorage.getItem("permission") === "admin"
};


export const isMemberLogin = () => {
    return localStorage.getItem("permission") === "member"
};


const UserService = {

  createUser,
  addCard,
  editProfile,
  changeAvatar,
  changePassword,
  updateUser,
  resendConfirmEmail,
  disableUser,
  getProfile,
  getUsers,
  getUser,
  // getMe,
  // getPaymentMethod,
  changePaymentMethod,
  // cancelSubscription,
};
export default UserService;

