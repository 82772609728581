
import React, {useEffect, useState} from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col, Button, Badge,
} from "reactstrap";
import DataTable from 'react-data-table-component';
import InfoIcon from "react-bootstrap-sweetalert/dist/components/InfoIcon";
import {getCalls, getUsers} from "../../../services/user.service";


function CallLogs() {
    const columns = [
                    {
                        name: 'Publisher',
                        selector: row => row.publisher,
                        sortable: false,
                    },
                    {
                        name: 'Subscriber',
                        selector: row => row.subscriber,
                        sortable: false,
                    },
                    {
                        name: 'Created At',
                        selector: row => row.created_at,
                        sortable: true,
                    },
                    {
                        name: 'Finished At',
                        selector: row => row.finished_at,
                        sortable: false,
                    },
                    {
                        name: 'Duration',
                        selector: row => row.duration,
                        sortable: false,
                    },
                    {
                        name: 'Status',
                        selector: row => row.status,
                        sortable: false,
                    }
                ]


    const [data,setData] = useState([])
    useEffect(()=>{
        getCalls().then((response)=>{
            if (response.data !== undefined && response.data !== null){
                let dlist = []

                response.data.forEach(item=>{
                    if (item.finished_at.includes("0001-01-01")){
                        item.finished_at = "- Not Finished Yet -"
                    }
                    dlist.push( {
                      id:  item.id,
                      publisher: item.publisher  + " (" +item.publisher_ip + ")",
                      subscriber: item.subscriber + " (" +item.subscriber_ip + ")",
                      created_at: item.created_at,
                      finished_at: item.finished_at,
                      duration: item.duration + " Min",
                      status:(<Badge color={item.status === "finished" ? "success" : "info"} pill>{item.status}</Badge>),
                  },)
                })
                setData(dlist)
            }
            // console.log(response)
        }, (error)=>{
            console.log(error)
        })
    },[])

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <DataTable
                      columns={columns}
                      data={data}
                      pagination={true}
                      expandableRows={false}
                      title={"Call Logs"}
                      responsive={true}
                      defaultSortAsc={true}
                      expandableInheritConditionalStyles={true}
                      noTableHead={false}
                      pointerOnHover={true}
                      highlightOnHover={true}
                      noContextMenu={false}

                />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CallLogs;
