import axios from "axios";


const BASE_OUTPUT_URL = "https://api.dcmarketing.ca/api/output/";
// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_API_URL = "https://api.dcmarketing.ca/";

// alert(BASE_API_URL)
const PUBLIC_API_URL =  `${BASE_API_URL}api/`;
const PRIVATE_API_URL = `${BASE_API_URL}api/`;

export const getOutputURL = () => { return BASE_OUTPUT_URL; };
export const getPublicURL = () => { return PUBLIC_API_URL; };
export const getPrivateURL = () => { return PRIVATE_API_URL; };
export const getFrontEndURL = () => { return process.env.REACT_APP_FRONT_URL ; };
export function toPascalCase (str) {
    if (str !== undefined && str != null){

        if (/^[\p{L}\d]+$/iu.test(str)) {
          return str.charAt(0).toUpperCase() + str.slice(1);
        }
        return str.replace(
          /([\p{L}\d])([\p{L}\d]*)/giu,
          (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
        ).replace(/[^\p{L}\d]/giu, '');
    }
    return str
  }
export const getProcessType = () =>{
    let concatString = toPascalCase(localStorage.getItem("pf"))+ " -> " + toPascalCase(localStorage.getItem("pe"))  ;
    if (localStorage.getItem("of") !== undefined && localStorage.getItem("of") !== null ){
       concatString += " -> " +  toPascalCase(localStorage.getItem("of"))
    }
    if (localStorage.getItem("of2") !== undefined && localStorage.getItem("of2") !== null ){
        concatString += " -> " +   toPascalCase(localStorage.getItem("of2"))
    }
    if (localStorage.getItem("ft") !== undefined && localStorage.getItem("ft") !== null ){
        concatString += " -> " +  toPascalCase(localStorage.getItem("ft"))
    }
    return concatString
}


const CoreService = {
  getPrivateURL,
  getPublicURL,
  BASE_API_URL,
  getProcessType,
  toPascalCase,
  getFrontEndURL,
};
export default CoreService;
