import axios from "axios";

import CoreService, {getPrivateURL, getPublicURL} from "./core.service";
export const getUser = (token) => axios.get(`${getPublicURL()}getUser?activation=` + token);

export const login = (email, password) => {
  logout();
  const raw = JSON.stringify({password, email});
  return axios.post(`${getPublicURL()}login`, raw);
};

export const logout = () => {
  localStorage.clear();
  eraseCookie();
};

export const getUserIsLogin = () => {
  return localStorage.getItem("token") !== undefined && localStorage.getItem("token") !== null ; //.indexOf('Authorization=') > -1 ;
};

function eraseCookie() {
  document.cookie.split(";").forEach(
    function(c) {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/");
    });
}

function setToken (token){
  localStorage.setItem("token", token)
}

const AuthService = {
  login,
  logout,
  setToken,
};
export default AuthService;
