
import React, {useEffect, useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";
import AuthService from "../../services/auth.services";
import {toast, ToastContainer} from "react-toastify";


// react-router-dom components
import {Link, useNavigate} from "react-router-dom";
function Login() {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  useEffect(()=>{
    AuthService.logout();
    document.body.classList.toggle("login-page");
    return function cleanup() { document.body.classList.toggle("login-page"); };
  },[])
  const doLogin = (e) =>{
    e.preventDefault();
    setLoading(true);
    AuthService.login(email, password).then(
      (data) => {
        setLoading(false);
        AuthService.setToken(data.data["token"])
        navigate("/admin/projects");
      },
      (e) => {
        alert("Username or password was incorrect")
        setLoading(false);
      }
    );
  }
  return (
    <div className="login-page">
      <Container>

        <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form action="" className="form" method="">
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    <h3 className="header text-center">Login</h3>
                  </CardHeader>
                </CardHeader>
                <CardBody>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Username" value={email} type="text" onChange={(e)=>setEmail(e.target.value)} />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" value={password} type="password" autoComplete="off" onChange={(e)=>setPassword(e.target.value)}/>
                  </InputGroup>
                  <br />
                  <FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input defaultChecked defaultValue="" type="checkbox" />
                        <span className="form-check-sign" />
                        Remember Me
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button block className="btn btn-round mb-3" color="success" style={{"background":"darkgreen"}} disabled={loading} onClick={doLogin}>
                    Login To Dashboard
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/bg/question.jpg")})`,
        }}
      />
    </div>
  );
}

export default Login;
